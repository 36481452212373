<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
                <span>
                    {{ this.$t('settings.lang_edit_permgrp') }}
                </span>
        <v-spacer/>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                :disabled="loading"
                :loading="loading"
                @click="update"
                color="white"
                dark
                small
                text
                v-on="on"
            >
              <v-icon color="primary" dark>save</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('generic.lang_save') }}</span>
        </v-tooltip>
      </v-card-title>
      <v-divider class="ma-0 pa-0"/>
      <v-card-text>
        <v-form lazy-validation ref="edit_group_form">
          <v-row>

            <v-col cols="12" sm="6">
              <v-text-field :label="this.$t('settings.lang_name_permgrp')" :rules="[v => !!v]" dense
                            name="perm_group_name" outlined v-model="data.perm_group_name"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select :items="this.startingPoints" :label="this.$t('generic.lang_Startpunkt')"
                        :rules="[v => !!v]" dense item-text="name"
                        item-value="id" name="permissionGroupStartingPoint" outlined
                        v-model="data.permissionGroupStartingPoint"/>
            </v-col>

            <v-col cols="12" sm="12">

              <v-expansion-panels class="ma-0 pa-0" focusable popout>
                <v-expansion-panel
                    :key="i"
                    v-for="(item , i) in this.permissionsAvailable"
                >
                  <v-expansion-panel-header>{{ $t(i) }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th>{{ $t('settings.lang_permName') }}</th>
                          <th>{{ $t('settings.lang_permDescription') }}</th>
                          <th>
                            {{ $t('generic.lang_status') }}
                            <v-switch :value="i" @change="checkAll(item , i)"
                                      v-model="selected[i]"/>
                          </th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr :key="j" v-for="(elt , j) in item">
                          <td>{{ $t(elt.permName) }}</td>
                          <td>{{ $t(elt.permDescr) }}</td>
                          <td>
                            <v-switch :id="elt.permDef.replace(' ', '').toLowerCase()"
                                      :value="elt.permDef" @change="checked(item , elt , i)"
                                      v-model="data[elt.permDef]"/>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
//configs
import {ENDPOINTS} from "../../../../config";
import {Events} from "../../../../plugins/events";

export default {
  name: "UserGroupCreateComponent",
  data: () => {
    return {
      ENDPOINTS,
      loading: false,
      startingPoints: null,
      permissionsAvailable: null,
      data: {
        perm_group_name: "",
        targetSales: "",
        permissionGroupStartingPoint: ""
      },
      selected: {}
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.USERGROUP.GET, {
        permGrpID: this.$route.params.id
      }).then((res) => {

        this.data.perm_group_name = res.data.formfillData.textFields.perm_group_name;
        this.data.targetSales = res.data.formfillData.textFields.targetSales;
        this.data.permissionGroupStartingPoint = res.data.formfillData.startingPoint;
        this.startingPoints = res.data.startingPoints;
        this.permissionsAvailable = res.data.permissionsAvailable;

        //select attached permissions
        for (const key in res.data.formfillData.checkBoxData)
          if (res.data.formfillData.checkBoxData.hasOwnProperty(key) && res.data.formfillData.checkBoxData[key] == 1)
            this.data[key] = key;

        //set permGrpID
        this.data.permGrpID = this.$route.params.id;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      })
    },
    update() {
      if (!this.$refs.edit_group_form.validate()) return;
      this.loading = true;

      for (let attr in this.data) {
        if (this.data.hasOwnProperty(attr) && this.data[attr] === "")
          delete this.data[attr];
      }

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.USERGROUP.UPDATE, this.data).then((res) => {
        //console.log(res)

        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_ActionSuccessful')
          });

          this.$router.push('/settings/basicSettings/permissions');
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    checkAll(item, value) {
      if (this.selected.hasOwnProperty(value) && this.selected[value] !== null) {

        let start = async () => {
          item.forEach((elt) => {
            if (this.data[elt.permDef] === undefined || this.data[elt.permDef] == null)
              document.getElementById(elt.permDef.replace(' ', '').toLowerCase()).click();
          });
        };

        start();

      } else {
        let s = 0;
        item.forEach((elt) => {
          if (this.data.hasOwnProperty(elt.permDef) && this.data[elt.permDef] != null)
            ++s;
        });

        if (s == item.length)
          item.forEach((elt) => {
            this.data[elt.permDef] = null;
          });
      }
    },
    checked(item, elt, parent) {
      if (this.data.hasOwnProperty(elt.permDef) && this.data[elt.permDef] !== null) {
        let s = 0;

        item.forEach((attr) => {
          if (this.data.hasOwnProperty(attr.permDef) && this.data[attr.permDef] != null)
            ++s;

          if (this.data.hasOwnProperty(attr.permDef) && this.data[attr.permDef] == null) {
            delete this.data[attr.permDef];
          }
        })
        if (s == item.length)
          this.selected[parent] = parent;
      } else {

        item.forEach((attr) => {
          if (this.data.hasOwnProperty(attr.permDef) && this.data[attr.permDef] == null) {
            delete this.data[attr.permDef];
          }
        })
        this.selected[parent] = null;
      }
    }
  }
}
</script>
